import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_URL
const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common['Authorization'] = token
}
axios.interceptors.request.use(request => {
  if(process.env.NODE_ENV !== 'production')
  console.log('Starting Request', JSON.stringify(request, null, 2))
  return request
})

axios.interceptors.response.use(response => {
  if(process.env.NODE_ENV !== 'production')
  console.log('Response:', JSON.stringify(response, null, 2))
  return response
})
export default axios;
