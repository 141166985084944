export function calculateDeviation(position, route_stops, deviation){
  let pos = new window.google.maps.LatLng(position.lat, position.lng);
  for(let i = 0;i<route_stops.length;i++){
    let latlng = new window.google.maps.LatLng(route_stops[i].lat, route_stops[i].lng);
    let dist = window.google.maps.geometry.spherical.computeDistanceBetween(pos, latlng)
    if(dist <= deviation){
      return true
    }
  }
  return false
}

export function formatNumber(value){
  return value ? value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : value
}
