import {createWebHistory, createRouter} from 'vue-router'

import Home from '../home/Home.vue';
import Login from '../login/Login.vue';
import Profile from '../profile/Profile.vue'

import Account from '../account/Account.vue'
import AddAccount from '../account/AddAccount.vue'
import EditAccount from '../account/EditAccount.vue'

import EditAccountCapability from '../account_capability/EditAccountCapability.vue'

import User from '../user/User.vue'
import AddUser from '../user/AddUser.vue'
import EditUser from '../user/EditUser.vue'

import EditUserCapability from '../user_capability/EditUserCapability.vue'

import Vehicle from '../vehicle/Vehicle.vue'
import AddVehicle from '../vehicle/AddVehicle.vue'
import EditVehicle from '../vehicle/EditVehicle.vue'

import Driver from '../driver/Driver.vue'
import AddDriver from '../driver/AddDriver.vue'
import EditDriver from '../driver/EditDriver.vue'

import Gps from '../gps/Gps.vue'
import AddGps from '../gps/AddGps.vue'
import EditGps from '../gps/EditGps.vue'

import GpsCamera from '../gps_cameras/GpsCamera.vue'
import AddGpsCamera from '../gps_cameras/AddGpsCamera.vue'
import EditGpsCamera from '../gps_cameras/EditGpsCamera.vue'

import SimCard from '../sim_card/SimCard.vue'
import AddSimCard from '../sim_card/AddSimCard.vue'
import EditSimCard from '../sim_card/EditSimCard.vue'

import Ibutton from '../ibutton/Ibutton.vue'
import AddIbutton from '../ibutton/AddIbutton.vue'
import EditIbutton from '../ibutton/EditIbutton.vue'

import WorkOrder from '../work_order/WorkOrder.vue'
import AddWorkOrder from '../work_order/AddWorkOrder.vue'
import EditWorkOrder from '../work_order/EditWorkOrder.vue'

import MaintenanceSchedule from '../maintenance_schedule/MaintenanceSchedule.vue'
import AddMaintenanceSchedule from '../maintenance_schedule/AddMaintenanceSchedule.vue'
import EditMaintenanceSchedule from '../maintenance_schedule/EditMaintenanceSchedule.vue'
import MaintenanceReminder from '../maintenance_schedule/MaintenanceReminder.vue'
import MaintenanceHistoryByVehicle from '../maintenance_schedule/MaintenanceHistoryByVehicle.vue'


import Route from '../route/Route.vue'
import AddRoute from '../route/AddRoute.vue'
import EditRoute from '../route/EditRoute.vue'
import RouteStop from '../route/RouteStop.vue'
import RouteVehicle from '../route/RouteVehicle.vue'
import RouteDraw from '../route/RouteDraw.vue'

import SubRoute from '../sub_route/SubRoute.vue'
import AddSubRoute from '../sub_route/AddSubRoute.vue'
import EditSubRoute from '../sub_route/EditSubRoute.vue'

import Alert from '../alert/Alert.vue'
import AddAlert from '../alert/AddAlert.vue'
import EditAlert from '../alert/EditAlert.vue'

import Sensor from '../sensor/Sensor.vue'
import AddSensor from '../sensor/AddSensor.vue'
import EditSensor from '../sensor/EditSensor.vue'

import GpsCommand from '../command/GpsCommand.vue'
import TelegramSetting from '../telegram_setting/TelegramSetting.vue'

import Stop from '../stop/Stop.vue'
import AddStop from '../stop/AddStop.vue'
import EditStop from '../stop/EditStop.vue'

import Trip from '../trip/Trip.vue'
import AddTrip from '../trip/AddTrip.vue'
import EditTrip from '../trip/EditTrip.vue'
import AdjustTrip from '../trip/AdjustTrip.vue'
import UploadTrip from '../trip/UploadTrip.vue'
import PlaybackTrip from '../trip/PlaybackTrip.vue'

import Spda from '../spda/Spda.vue'
import AddSpda from '../spda/AddSpda.vue'
import EditSpda from '../spda/EditSpda.vue'
import AdjustSpda from '../spda/AdjustSpda.vue'

import RoadWarrant from '../road_warrant/RoadWarrant.vue'
import AddRoadWarrant from '../road_warrant/AddRoadWarrant.vue'
import EditRoadWarrant from '../road_warrant/EditRoadWarrant.vue'

import TripSchedule from '../trip_schedule/TripSchedule.vue'
import AddTripSchedule from '../trip_schedule/AddTripSchedule.vue'
import EditTripSchedule from '../trip_schedule/EditTripSchedule.vue'

import VehicleRoute from '../vehicle_route/VehicleRoute.vue'
import AddVehicleRoute from '../vehicle_route/AddVehicleRoute.vue'
import EditVehicleRoute from '../vehicle_route/EditVehicleRoute.vue'

import CaseIncident from '../case_incident/CaseIncident.vue'
import AddCaseIncident from '../case_incident/AddCaseIncident.vue'
import EditCaseIncident from '../case_incident/EditCaseIncident.vue'

import RouteTarget from '../route_target/RouteTarget.vue'
import AddRouteTarget from '../route_target/AddRouteTarget.vue'
import EditRouteTarget from '../route_target/EditRouteTarget.vue'

import MonitorSchematic from '../monitor/MonitorSchematic.vue'
import MonitorRouteVehicle from '../monitor/MonitorRouteVehicle.vue'
import MonitorVehicle from '../monitor/MonitorVehicle.vue'
import MonitorLiveMap from '../monitor/MonitorLiveMap.vue'
import MonitorCustomEventAlert from '../monitor/MonitorCustomEventAlert.vue'
import EditCustomEventAlert from '../monitor/EditCustomEventAlert.vue'
import LiveVideoAlert from '../monitor/LiveVideoAlert.vue'
import LiveVideoStreaming from '../monitor/LiveVideoStreaming.vue'

import LocationHistory from '../report/LocationHistory.vue'
import HistoryVehicle from '../report/HistoryVehicle.vue'
import JourneyVehicle from '../report/JourneyVehicle.vue'
import CheckpointVehicle from '../report/CheckpointVehicle.vue'
import Punctuality from '../report/Punctuality.vue'
import ExpiredLicense from '../report/ExpiredLicense.vue'
import ReportCustomAlert from '../report/ReportCustomAlert.vue'
import ReportHistorySensor from '../report/ReportHistorySensor.vue'
import ReportGraphSensor from '../report/ReportGraphSensor.vue'
import ReportCamera from '../report/ReportCamera.vue'
import ReportOperationalVehicles from '../report/ReportOperationalVehicles.vue'
import ReportHistoryVideoAlert from '../report/ReportHistoryVideoAlert.vue'
import ReportStopBusTime from '../report/ReportStopBusTime.vue'
import ReportPassengerFlow from '../report/ReportPassengerFlow.vue'
import ReportDriverJourney from '../report/ReportDriverJourney.vue'
import ReportDriverRanking from '../report/ReportDriverRanking.vue'
import ReportRouteTarget from '../report/ReportRouteTarget.vue'
import ReportGenerate from '../report/ReportGenerate.vue'
import InsertPosition from '../report/InsertPosition.vue'
import ReportLoginLog from '../report/ReportLoginLog.vue'
import ReportDriverLogin from '../report/ReportDriverLogin.vue'
import ReportGeofence from '../report/ReportGeofence.vue'
import ReportGeofenceSummaryKm from '../report/ReportGeofenceSummaryKm.vue'
import ReportSyncGeofenceSummaryKm from '../report/ReportSyncGeofenceSummaryKm.vue'
import AdjustGeofenceSummaryKm from '../report/AdjustGeofenceSummaryKm.vue'

import ChecklistModel from '../checklist_model/ChecklistModel.vue'
import AddChecklistModel from '../checklist_model/AddChecklistModel.vue'
import EditChecklistModel from '../checklist_model/EditChecklistModel.vue'

import ChecklistItem from '../checklist_item/ChecklistItem.vue'
import AddChecklistItem from '../checklist_item/AddChecklistItem.vue'
import EditChecklistItem from '../checklist_item/EditChecklistItem.vue'

import ChecklistUser from '../checklist_user/ChecklistUser.vue'
import AddChecklistUser from '../checklist_user/AddChecklistUser.vue'
import EditChecklistUser from '../checklist_user/EditChecklistUser.vue'

import Checklist from '../checklist/Checklist.vue'
import AddChecklist from '../checklist/AddChecklist.vue'
import EditChecklist from '../checklist/EditChecklist.vue'
import PrintChecklist from '../checklist/PrintChecklist.vue'

import Delivery from '../delivery/Delivery.vue'
import AddDelivery from '../delivery/AddDelivery.vue'
import EditDelivery from '../delivery/EditDelivery.vue'

import Cost from '../cost/Cost.vue'
import AddCost from '../cost/AddCost.vue'
import EditCost from '../cost/EditCost.vue'

import Part from '../part/Part.vue'
import AddPart from '../part/AddPart.vue'
import EditPart from '../part/EditPart.vue'

import Inventory from '../inventory/Inventory.vue'
import AddInventory from '../inventory/AddInventory.vue'
import EditInventory from '../inventory/EditInventory.vue'
import InventoryHistory from '../inventory/InventoryHistory.vue'

import PartMileage from '../part_mileage/PartMileage.vue'
import AddPartMileage from '../part_mileage/AddPartMileage.vue'
import EditPartMileage from '../part_mileage/EditPartMileage.vue'

import VehicleSharing from '../vehicle_sharing/VehicleSharing.vue'
import AddVehicleSharing from '../vehicle_sharing/AddVehicleSharing.vue'

import CeibaServer from '../ceiba_server/CeibaServer.vue'
import AddCeibaServer from '../ceiba_server/AddCeibaServer.vue'
import EditCeibaServer from '../ceiba_server/EditCeibaServer.vue'

import CeibaDevice from '../ceiba_device/CeibaDevice.vue'
import AddCeibaDevice from '../ceiba_device/AddCeibaDevice.vue'
import EditCeibaDevice from '../ceiba_device/EditCeibaDevice.vue'

import PaymentLink from '../payment_link/PaymentLink.vue'
import AddPaymentLink from '../payment_link/AddPaymentLink.vue'
import EditPaymentLink from '../payment_link/EditPaymentLink.vue'

import Sales from '../sales/Sales.vue'
import AddSales from '../sales/AddSales.vue'
import EditSales from '../sales/EditSales.vue'

import NotFound from '../notfound/NotFound.vue'

const routes = [
  {
    path: '/',
    component: Home,
    name: 'home',
    children: [
      {
        path: '/profile',
        component: Profile,
        name: 'profile'
      },

      //ACCOUNTS
      {
        path: '/accounts',
        component: Account,
        name: 'accounts'
      },
      {
        path: '/accounts/add',
        component: AddAccount,
        name: 'add_account'
      },
      {
        path: '/accounts/edit/:id',
        component: EditAccount,
        name: 'edit_account'
      },

      //ACCOUNT CAPABILITY
      {
        path: '/account_capabilities/edit/:id',
        component: EditAccountCapability,
        name: 'edit_account_capability'
      },

      //USERS
      {
        path: '/users',
        component: User,
        name: 'users'
      },
      {
        path: '/users/add',
        component: AddUser,
        name: 'add_user'
      },
      {
        path: '/users/edit/:id',
        component: EditUser,
        name: 'edit_user'
      },

      //USER CAPABILITY
      {
        path: '/user_capabilities/edit/:id',
        component: EditUserCapability,
        name: 'edit_user_capability'
      },

      //VEHICLES
      {
        path: '/vehicles',
        component: Vehicle,
        name: 'vehicles'
      },
      {
        path: '/vehicles/add',
        component: AddVehicle,
        name: 'add_vehicle'
      },
      {
        path: '/vehicles/edit/:id',
        component: EditVehicle,
        name: 'edit_vehicle'
      },

      //DRIVERS
      {
        path: '/drivers',
        component: Driver,
        name: 'drivers'
      },
      {
        path: '/drivers/add',
        component: AddDriver,
        name: 'add_driver'
      },
      {
        path: '/drivers/edit/:id',
        component: EditDriver,
        name: 'edit_driver'
      },

      //GPS
      {
        path: '/gps',
        component: Gps,
        name: 'gps'
      },
      {
        path: '/gps/add',
        component: AddGps,
        name: 'add_gps'
      },
      {
        path: '/gps/edit/:id',
        component: EditGps,
        name: 'edit_gps'
      },

      //GPS
      {
        path: '/gps_cameras',
        component: GpsCamera,
        name: 'gps_cameras'
      },
      {
        path: '/gps_cameras/add',
        component: AddGpsCamera,
        name: 'add_gps_camera'
      },
      {
        path: '/gps_camera/edit/:id',
        component: EditGpsCamera,
        name: 'edit_gps_camera'
      },

      //SIMCARD
      {
        path: '/sim_cards',
        component: SimCard,
        name: 'sim_cards'
      },
      {
        path: '/sim_cards/add',
        component: AddSimCard,
        name: 'add_sim_card'
      },
      {
        path: '/sim_cards/edit/:id',
        component: EditSimCard,
        name: 'edit_sim_card'
      },

      //IBUTTON
      {
        path: '/ibuttons',
        component: Ibutton,
        name: 'ibuttons'
      },
      {
        path: '/ibuttons/add',
        component: AddIbutton,
        name: 'add_ibutton'
      },
      {
        path: '/sim_cards/edit/:id',
        component: EditIbutton,
        name: 'edit_ibutton'
      },

      //WORK ORDER
      {
        path: '/work_orders',
        component: WorkOrder,
        name: 'work_orders'
      },
      {
        path: '/work_orders/add',
        component: AddWorkOrder,
        name: 'add_work_order'
      },
      {
        path: '/work_orders/edit/:id',
        component: EditWorkOrder,
        name: 'edit_work_order'
      },

      //ROUTES
      {
        path: '/routes',
        component: Route,
        name: 'routes'
      },
      {
        path: '/routes/add',
        component: AddRoute,
        name: 'add_route'
      },
      {
        path: '/routes/edit/:id',
        component: EditRoute,
        name: 'edit_route'
      },
      {
        path: '/route_stops/edit/:id',
        component: RouteStop,
        name: 'route_stops'
      },
      {
        path: '/route_vehicles/edit/:id',
        component: RouteVehicle,
        name: 'route_vehicles'
      },
      {
        path: '/route_draws/edit/:id',
        component: RouteDraw,
        name: 'route_draws'
      },

      //SUB ROUTES
      {
        path: '/sub_routes',
        component: SubRoute,
        name: 'sub_routes'
      },
      {
        path: '/sub_routes/add',
        component: AddSubRoute,
        name: 'add_sub_route'
      },
      {
        path: '/sub_routes/edit/:id',
        component: EditSubRoute,
        name: 'edit_sub_route'
      },

      //ALERT
      {
        path: '/alerts',
        component: Alert,
        name: 'alerts'
      },
      {
        path: '/alerts/add',
        component: AddAlert,
        name: 'add_alert'
      },
      {
        path: '/alerts/edit/:id',
        component: EditAlert,
        name: 'edit_alert'
      },

      //SENSOR
      {
        path: '/sensors',
        component: Sensor,
        name: 'sensors'
      },
      {
        path: '/sensors/add',
        component: AddSensor,
        name: 'add_sensor'
      },
      {
        path: '/sensors/edit/:id',
        component: EditSensor,
        name: 'edit_sensor'
      },

      //COMMAND
      {
        path: '/gps_commands',
        component: GpsCommand,
        name: 'gps_commands'
      },

      //TelegramSetting
      {
        path: '/telegram_settings',
        component: TelegramSetting,
        name: 'telegram_settings'
      },

      //STOPS
      {
        path: '/stops',
        component: Stop,
        name: 'stops'
      },
      {
        path: '/stops/add',
        component: AddStop,
        name: 'add_stop'
      },
      {
        path: '/stops/edit/:id',
        component: EditStop,
        name: 'edit_stop'
      },

      //TRIPS
      {
        path: '/trips',
        component: Trip,
        name: 'trips'
      },
      {
        path: '/trips/add',
        component: AddTrip,
        name: 'add_trip'
      },
      {
        path: '/trips/edit/:id',
        component: EditTrip,
        name: 'edit_trip'
      },
      {
        path: '/trips/adjust/:id',
        component: AdjustTrip,
        name: 'adjust_trip'
      },
      {
        path: '/upload_trips',
        component: UploadTrip,
        name: 'upload_trips'
      },
      {
        path: '/playback_trip/:id',
        component: PlaybackTrip,
        name: 'playback_trip'
      },

      //SPDAS
      {
        path: '/spdas',
        component: Spda,
        name: 'spdas'
      },
      {
        path: '/spdas/add',
        component: AddSpda,
        name: 'add_spda'
      },
      {
        path: '/spdas/edit/:id',
        component: EditSpda,
        name: 'edit_spda'
      },
      {
        path: '/spdas/adjust/:id',
        component: AdjustSpda,
        name: 'adjust_spda'
      },

      //ROAD WARRANTS
      {
        path: '/road_warrants',
        component: RoadWarrant,
        name: 'road_warrants'
      },
      {
        path: '/road_warrants/add',
        component: AddRoadWarrant,
        name: 'add_road_warrant'
      },
      {
        path: '/road_warrants/edit/:id',
        component: EditRoadWarrant,
        name: 'edit_road_warrant'
      },

      //TRIP SCHEDULES
      {
        path: '/trip_schedules',
        component: TripSchedule,
        name: 'trip_schedules'
      },
      {
        path: '/trip_schedules/add',
        component: AddTripSchedule,
        name: 'add_trip_schedule'
      },
      {
        path: '/trip_schedules/edit/:id',
        component: EditTripSchedule,
        name: 'edit_trip_schedule'
      },

      //VEHICLE ROUTE
      {
        path: '/vehicle_routes',
        component: VehicleRoute,
        name: 'vehicle_routes'
      },
      {
        path: '/vehicle_routes/add',
        component: AddVehicleRoute,
        name: 'add_vehicle_routes'
      },
      {
        path: '/vehicle_routes/edit/:id',
        component: EditVehicleRoute,
        name: 'edit_vehicle_routes'
      },

      //CASE INCIDENTS
      {
        path: '/case_incidents',
        component: CaseIncident,
        name: 'case_incidents'
      },
      {
        path: '/case_incidents/add',
        component: AddCaseIncident,
        name: 'add_case_incident'
      },
      {
        path: '/case_incidents/edit/:id',
        component: EditCaseIncident,
        name: 'edit_case_incident'
      },

      //ROUTE TARGET
      {
        path: '/route_targets',
        component: RouteTarget,
        name: 'route_targets'
      },
      {
        path: '/route_targets/add',
        component: AddRouteTarget,
        name: 'add_route_target'
      },
      {
        path: '/route_targets/edit/:id',
        component: EditRouteTarget,
        name: 'edit_route_target'
      },

      //MONITORING
      {
        path: '/monitor_schematics',
        component: MonitorSchematic,
        name: 'monitor_schematics'
      },
      {
        path: '/monitor_vehicles',
        component: MonitorVehicle,
        name: 'monitor_vehicles'
      },
      {
        path: '/monitor_route_vehicles',
        component: MonitorRouteVehicle,
        name: 'monitor_route_vehicles'
      },
      {
        path: '/live_maps',
        component: MonitorLiveMap,
        name: 'live_maps'
      },
      {
        path: '/monitor_custom_event_alerts',
        component: MonitorCustomEventAlert,
        name: 'monitor_custom_event_alerts'
      },
      {
        path: '/custom_event_alerts/edit/:id',
        component: EditCustomEventAlert,
        name: 'edit_custom_event_alert'
      },
      {
        path: '/live_video_alerts',
        component: LiveVideoAlert,
        name: 'live_video_alerts'
      },
      {
        path: '/live_video_streamings',
        component: LiveVideoStreaming,
        name: 'live_video_streamings'
      },

      //REPORT
      {
        path: '/report_location_histories',
        component: LocationHistory,
        name: 'report_location_histories'
      },
      {
        path: '/report_history_vehicles',
        component: HistoryVehicle,
        name: 'report_history_vehicles'
      },
      {
        path: '/report_journey_vehicles',
        component: JourneyVehicle,
        name: 'report_journey_vehicles'
      },
      {
        path: '/report_checkpoint_vehicles',
        component: CheckpointVehicle,
        name: 'report_checkpoint_vehicles'
      },
      {
        path: '/report_punctuality',
        component: Punctuality,
        name: 'report_punctuality'
      },
      {
        path: '/report_expired_licenses',
        component: ExpiredLicense,
        name: 'report_expired_licenses'
      },
      {
        path: '/report_custom_alerts',
        component: ReportCustomAlert,
        name: 'report_custom_alerts'
      },
      {
        path: '/report_history_sensors',
        component: ReportHistorySensor,
        name: 'report_history_sensors'
      },
      {
        path: '/report_graph_sensors',
        component: ReportGraphSensor,
        name: 'report_graph_sensors'
      },
      {
        path: '/report_cameras',
        component: ReportCamera,
        name: 'report_cameras'
      },
      {
        path: '/report_operational_vehicles',
        component: ReportOperationalVehicles,
        name: 'report_operational_vehicles'
      },
      {
        path: '/report_history_video_alerts',
        component: ReportHistoryVideoAlert,
        name: 'report_history_video_alerts'
      },
      {
        path: '/report_stop_bus_time',
        component: ReportStopBusTime,
        name: 'report_stop_bus_time'
      },
      {
        path: '/report_passenger_flow',
        component: ReportPassengerFlow,
        name: 'report_passenger_flow'
      },
      {
        path: '/report_driver_journey',
        component: ReportDriverJourney,
        name: 'report_driver_journey'
      },
      {
        path: '/report_driver_ranking',
        component: ReportDriverRanking,
        name: 'report_driver_ranking'
      },
      {
        path: '/report_route_target',
        component: ReportRouteTarget,
        name: 'report_route_target'
      },
      {
        path: '/report_generate',
        component: ReportGenerate,
        name: 'report_generate'
      },
      {
        path: '/insert_position',
        component: InsertPosition,
        name: 'insert_position'
      },
      {
        path: '/report_login_log',
        component: ReportLoginLog,
        name: 'report_login_log'
      },
      {
        path: '/report_driver_login',
        component: ReportDriverLogin,
        name: 'report_driver_login'
      },
      {
        path: '/report_geofence',
        component: ReportGeofence,
        name: 'report_geofence'
      },
      {
        path: '/report_geofence_summary_km',
        component: ReportGeofenceSummaryKm,
        name: 'report_geofence_summary_km'
      },
      {
        path: '/report_sync_geofence_summary_km',
        component: ReportSyncGeofenceSummaryKm,
        name: 'report_sync_geofence_summary_km'
      },
      {
        path: '/adjust_geofence_summary_km',
        component: AdjustGeofenceSummaryKm,
        name: 'adjust_geofence_summary_km'
      },

      //CHECKLIST MODEL
      {
        path: '/checklists',
        component: Checklist,
        name: 'checklist'
      },
      {
        path: '/checklists/add',
        component: AddChecklist,
        name: 'add_checklist'
      },
      {
        path: '/checklists/edit/:id',
        component: EditChecklist,
        name: 'edit_checklist'
      },
      {
        path: '/checklists/print/:id',
        component: PrintChecklist,
        name: 'print_checklist'
      },

      //CHECKLIST MODEL
      {
        path: '/checklist_models',
        component: ChecklistModel,
        name: 'checklist_models'
      },
      {
        path: '/checklist_models/add',
        component: AddChecklistModel,
        name: 'add_checklist_model'
      },
      {
        path: '/checklist_models/edit/:id',
        component: EditChecklistModel,
        name: 'edit_checklist_model'
      },

      //CHECKLIST ITEM
      {
        path: '/checklist_items',
        component: ChecklistItem,
        name: 'checklist_items'
      },
      {
        path: '/checklist_items/add',
        component: AddChecklistItem,
        name: 'add_checklist_item'
      },
      {
        path: '/checklist_items/edit/:id',
        component: EditChecklistItem,
        name: 'edit_checklist_item'
      },

      //CHECKLIST USER
      {
        path: '/checklist_users',
        component: ChecklistUser,
        name: 'checklist_users'
      },
      {
        path: '/checklist_users/add',
        component: AddChecklistUser,
        name: 'add_checklist_user'
      },
      {
        path: '/checklist_users/edit/:id',
        component: EditChecklistUser,
        name: 'edit_checklist_user'
      }, 
      
      //DELIVERIES
      {
        path: '/deliveries',
        component: Delivery,
        name: 'deliveries'
      },
      {
        path: '/deliveries/add',
        component: AddDelivery,
        name: 'add_delivery'
      },
      {
        path: '/deliveries/edit/:id',
        component: EditDelivery,
        name: 'edit_delivery'
      },

      //COSTS
      {
        path: '/costs',
        component: Cost,
        name: 'costs'
      },
      {
        path: '/costs/add',
        component: AddCost,
        name: 'add_cost'
      },
      {
        path: '/costs/edit/:id',
        component: EditCost,
        name: 'edit_cost'
      },

      //VEHICLE SHARINGS
      {
        path: '/vehicle_sharings',
        component: VehicleSharing,
        name: 'vehicle_sharings'
      },
      {
        path: '/vehicle_sharings/add',
        component: AddVehicleSharing,
        name: 'add_vehicle_sharing'
      },

      //PARTS
      {
        path: '/parts',
        component: Part,
        name: 'parts'
      },
      {
        path: '/parts/add',
        component: AddPart,
        name: 'add_part'
      },
      {
        path: '/parts/edit/:id',
        component: EditPart,
        name: 'edit_part'
      },

      //INVENTORIES
      {
        path: '/inventories',
        component: Inventory,
        name: 'inventories'
      },
      {
        path: '/inventories/add',
        component: AddInventory,
        name: 'add_inventory'
      },
      {
        path: '/inventories/edit/:id',
        component: EditInventory,
        name: 'edit_inventory'
      },
      {
        path: '/inventory_histories',
        component: InventoryHistory,
        name: 'inventory_histories'
      },

      //PART MILEAGES
      {
        path: '/part_mileages',
        component: PartMileage,
        name: 'part_mileages'
      },
      {
        path: '/part_mileages/add',
        component: AddPartMileage,
        name: 'add_part_mileage'
      },
      {
        path: '/part_mileages/edit/:id',
        component: EditPartMileage,
        name: 'edit_part_mileage'
      },

      //MAINTENANCE SCHEDULES
      {
        path: '/maintenance_schedules',
        component: MaintenanceSchedule,
        name: 'maintenance_schedules'
      },
      {
        path: '/maintenance_schedules/add',
        component: AddMaintenanceSchedule,
        name: 'add_maintenance_schedule'
      },
      {
        path: '/maintenance_schedules/edit/:id',
        component: EditMaintenanceSchedule,
        name: 'edit_maintenance_schedule'
      },
      {
        path: '/maintenance_reminders',
        component: MaintenanceReminder,
        name: 'maintenance_reminders'
      },
      {
        path: '/maintenance_history_by_vehicle',
        component: MaintenanceHistoryByVehicle,
        name: 'maintenance_history_by_vehicle'
      },

      //CEIBA SERVERS
      {
        path: '/ceiba_servers',
        component: CeibaServer,
        name: 'ceiba_servers'
      },
      {
        path: '/ceiba_servers/add',
        component: AddCeibaServer,
        name: 'add_ceiba_server'
      },
      {
        path: '/ceiba_servers/edit/:id',
        component: EditCeibaServer,
        name: 'edit_ceiba_server'
      },

      //CEIBA DEVICES
      {
        path: '/ceiba_devices',
        component: CeibaDevice,
        name: 'ceiba_devices'
      },
      {
        path: '/ceiba_devices/add',
        component: AddCeibaDevice,
        name: 'add_ceiba_device'
      },
      {
        path: '/ceiba_devices/edit/:id',
        component: EditCeibaDevice,
        name: 'edit_ceiba_device'
      },

      //PAYMENT LINK
      {
        path: '/payment_links',
        component: PaymentLink,
        name: 'payment_links'
      },
      {
        path: '/payment_links/add',
        component: AddPaymentLink,
        name: 'add_payment_link'
      },
      {
        path: '/payment_links/edit/:id',
        component: EditPaymentLink,
        name: 'edit_payment_link'
      },

      //SALES
      {
        path: '/sales',
        component: Sales,
        name: 'sales'
      },
      {
        path: '/sales/add',
        component: AddSales,
        name: 'add_sales'
      },
      {
        path: '/sales/edit/:id',
        component: EditSales,
        name: 'edit_sales'
      },

      { path: '/notFound', component: NotFound },
    ]
  },
  {
    path: '/login',
    component: Login,
    name: 'login'
  },
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  if (!to.matched.length) {
    if (loggedIn) {
      next('/notFound');
      return;
    } else {
      next('/login');
      return;
    }
  }
  if(authRequired) {
    if (loggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    if (loggedIn) {
      next('/')
      return
    }
    next()
  }
})
